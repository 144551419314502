<template>
    <div class="app-main-filter">
        <AppCheckInput 
            @checked="$emit('isVarified', $event)"
            :options="checkboxes.isProfileChecked"
            :checked="isVerifiedChecked" />
        <AppDoubleRangeInput
            @sendMinValue="$emit('minAge', $event)"
            @sendMaxValue="$emit('maxAge', $event)"
            :dropMinAge="dropMinAge"
            :dropMaxAge="dropMaxAge"
            />
        <AppDoubleRangeInputMoney
            @sendMinValue="$emit('minPrice', $event)"
            @sendMaxValue="$emit('maxPrice', $event)"
            :dropMinPrice="dropMinPrice"
            :dropMaxPrice="dropMaxPrice"
            />
        <h4 style="margin-bottom: 10px">Тип</h4>
        <label class="container"> Салон
            <input type="radio" name="type" id="saloon" @input="getMassagistType($event.target.id)" :checked="dropSaloon">    
            <span class="checkmark"></span>
        </label>
        <label class="container"> Частное лицо
            <input type="radio" name="type" id="massagist" @input="getMassagistType($event.target.id)" :checked="dropMassagist">    
            <span class="checkmark"></span>
        </label>
        <h4 style="margin-bottom: 10px">Пол</h4>
        <label class="container"> Массажистка
            <input type="radio" name="sex" id="female" class="sex-input"
                    :disabled="isSaloonActive"
                    :checked="dropFemale"
                    @input="$emit('sex', $event.target.id)">    
            <span class="checkmark"></span>
        </label>
        <label class="container"> Массажист
            <input type="radio" name="sex" id="male" class="sex-input"
                    :disabled="isSaloonActive"
                    :checked="dropMale"
                    @input="$emit('sex', $event.target.id)">    
            <span class="checkmark"></span>
        </label>
    </div>
</template>

<script>
import AppCheckInput from "@/components/inputs/app-check-input.vue";
import AppDoubleRangeInput from "@/components/inputs/app-double-range-input.vue";
import AppDoubleRangeInputMoney from "@/components/inputs/app-double-range-input-money.vue";
import AppRadioButton from "@/components/inputs/app-radio-input.vue";
    export default {
        props: {
            isVerifiedChecked: {
                type: Boolean,
                default: false
            },
            dropMinAge: {
                type: Boolean,
                default: false
            },
            dropMaxAge: {
                type: Boolean,
                default: false
            },
            dropSaloon: {
                type: Boolean,
                default: false
            },
            dropMassagist: {
                type: Boolean,
                default: false
            },
            dropMale: {
                type: Boolean,
                default: false
            },
            dropFemale: {
                type: Boolean,
                default: false
            },
            dropMinPrice: {
                type: Boolean,
                default: false
            },
            dropMaxPrice: {
                type: Boolean,
                default: false
            }
        },
        components: {
            AppCheckInput,
            AppDoubleRangeInput,
            AppDoubleRangeInputMoney,
            AppRadioButton
        },
        data(){
            return{
                checkboxes: {
                    isProfileChecked: {
                        label: "Только проверенные фото в анкете",
                        name: "checkedPhoto",
                        id: "profileChecked"
                    },
                },
                saloon: false,
                male: false,
                female: false,
                private: false
            }
        },
        methods: {
            getMinAge(e){
                console.log(e)
            },
            getMaxAge(e){
                console.log(e)
            },
            getMinPrice(e){
                console.log(e)
            },
            getMaxPrice(e){
                console.log(e)
            },
            getMassagistType(id){
                if(id === 'saloon'){
                    this.female = false;
                    this.male = false;
                    this.saloon = true;
                    document.querySelector("#female").checked = false
                    document.querySelector("#male").checked = false
                    this.$emit('status', id)
                } else {
                    this.saloon = false;
                    this.$emit('status', id)
                }
            },
        },
        computed: {
            isSaloonActive(){
                return this.saloon ? true : false 
            }
        }
    }
</script>

<style lang="scss" scoped>
    .app-main-filter{
        width: 100%;
        height: 100%;
        padding: 1em;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .range-slider-title{
            width: 100%;
            padding: 0.5em 0;
            font-size: 1em;
            font-weight: 700;
        }
         .range-fields{
                display: flex;
                justify-content: space-between;
                width: 100%;
                margin-bottom: 2em;
                .range-field{
                    width: 45%;
                    display: flex;
                    .field-label{
                        font-size: 1em;
                        font-weight: 700;
                        margin-right: 1em;
                    }
                    .field-input{
                        width: 42px;
                        border-radius: 3px;
                        border: 2px solid $accent;
                    }
                }
            }
            .select-label{
                font-weight: 700;
                margin-bottom: 0.5em;
                width: 100%;
            }
            .select-input{
                height: 20px;
            }

        .container{
    font-size: 1em;
    font-weight: 700;
    display: block;
    position: relative;
    cursor: pointer;
    user-select: none;
    padding-left: 30px;
    width: 100%;
    // padding: 1em 0;
    margin: 0 0 2em;
}
.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    background-color: $white;
}


.container input:checked ~ .checkmark {
    background-color: $white;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $accent-dark;
}


.container input:checked ~ .checkmark:after {
    display: block;
}
    }
</style>