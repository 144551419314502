<template>
    <div class="app-heart-btn" @click="$emit('action')">
        <span class="counter">{{ count }}</span>
        <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M30.1804 6.25142C29.715 5.04835 29.0438 3.95813 28.2045 3.04181C27.3646 2.12275 26.3743 1.39239 25.2875 0.89044C24.1606 0.367883 22.9519 0.100408 21.7316 0.103543C20.0196 0.103543 18.3492 0.626849 16.8977 1.61532C16.5504 1.85177 16.2205 2.11149 15.908 2.39446C15.5954 2.11149 15.2655 1.85177 14.9183 1.61532C13.4667 0.626849 11.7964 0.103543 10.0844 0.103543C8.8516 0.103543 7.65702 0.367134 6.52842 0.89044C5.43802 1.39436 4.45527 2.11924 3.61142 3.04181C2.77104 3.9571 2.09971 5.04757 1.6355 6.25142C1.15281 7.50348 0.90625 8.83306 0.90625 10.2014C0.90625 11.4922 1.14239 12.8373 1.61119 14.2057C2.0036 15.3492 2.56616 16.5354 3.28499 17.7331C4.42401 19.6287 5.99017 21.6056 7.93483 23.6097C11.1574 26.9317 14.3488 29.2265 14.4842 29.3195L15.3072 29.9087C15.6718 30.1684 16.1406 30.1684 16.5053 29.9087L17.3283 29.3195C17.4637 29.2226 20.6516 26.9317 23.8776 23.6097C25.8223 21.6056 27.3885 19.6287 28.5275 17.7331C29.2463 16.5354 29.8123 15.3492 30.2013 14.2057C30.6701 12.8373 30.9062 11.4922 30.9062 10.2014C30.9097 8.83306 30.6631 7.50348 30.1804 6.25142V6.25142ZM15.908 26.8425C15.908 26.8425 3.54544 18.0006 3.54544 10.2014C3.54544 6.25142 6.47286 3.04956 10.0844 3.04956C12.6229 3.04956 14.8245 4.63111 15.908 6.94141C16.9914 4.63111 19.1931 3.04956 21.7316 3.04956C25.3431 3.04956 28.2705 6.25142 28.2705 10.2014C28.2705 18.0006 15.908 26.8425 15.908 26.8425Z" fill="#8332B1"/>
    </svg>

    </div>
</template>

<script>
    export default {
        props: {
            count: {
                type: Number,
                default: 0
            }
        }
    }
</script>

<style lang="scss" scoped>
    .app-heart-btn{
        display: none;
        position: relative;
        cursor: pointer;
        .counter{
            position: absolute;
            top: 40%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 0.7em;
            color: $black;
        }
    }
    @media screen and(min-width: 1024px) {
        .app-heart-btn{
            display: block;
        }
    }
</style>