<template>
    <div class="app-header">
        <a href="#" class="app-logo"><img :src="require(`@/assets/logo.png`)" alt=""  @click="goToMainPage"></a>
        <AppMainHeaderNavigation />
        <AppFavoriteBtnHeader @action="goToFavoritesPage" v-if="!isUserMassagistOrSaloon" :count="countFavorites" />
        <AppRegularBtn dark text="регистрация" class="header-registration" @click="goToRegistrationPage" v-if="!isUserOrAdminLoggedIn" />
        <AppRegularBtn light text="вход" class="header-login" @click="goToLoginPage" v-if="!isUserOrAdminLoggedIn"/>
        <AppRegularBtn light text="личный кабинет" class="header-login" v-if="isUserMassagistOrSaloon" @click="goToPersonalPage"/>
        <AppRegularBtn light text="выход" class="header-login" @click="logout" v-if="isUserOrAdminLoggedIn"/>
    </div>
</template>

<script>
import AppMainHeaderNavigation from "@/components/navigation/app-main-header-navigation.vue";
import AppFavoriteBtnHeader from "@/components/buttons/app-favorite-btn-header.vue";
import AppRegularBtn from "@/components/buttons/app-regular-btn.vue";
    export default {
        props: {
            countFavorites: {
                type: Number,
                default: 0
            }
        },
        components: {
            AppMainHeaderNavigation,
            AppFavoriteBtnHeader,
            AppRegularBtn
        },
        methods: {
            goToRegistrationPage(){
                this.$router.push('/registration')
            },
            goToLoginPage(){
                this.$router.push('/login')
            },
            goToMainPage(){
                this.$router.push('/')
            },
            goToFavoritesPage(){
                this.$router.push('/favorites')
            },
            goToPersonalPage(){
                this.$router.push(`/massagist-personal-page/${this.$store.state.user.user.id}/massagist-anket`);
            },
            logout(){
                localStorage.removeItem('user');
                this.$store.dispatch('logout');
                this.$router.push('/');
            }
        },
        computed: {
            isUserOrAdminLoggedIn(){
                return this.$store.state.user.isLoggedIn || this.$store.state.admin.isLoggedIn ? true : false;
            },
            isUserMassagistOrSaloon(){
                return this.$store.state.user.status === 'massagist' || this.$store.state.user.status === 'saloon' ? true : false;
            },
        
        }
    }
</script>

<style lang="scss" scoped>
    .app-header{
        width: 100%;
        padding: 1em;
        display: flex;
        align-items: center;
        justify-content: center;
        .app-logo{
            img{
                // width: 30%;
            }
        }
        .header-login{
            display: none;
        }
        .header-registration{
            display: none;
        }
    }
    @media screen and(min-width: 768px){
       .app-header{
           justify-content: space-between;
           .header-registration{
               display: block;
           }
        
        }
    }
    @media screen and(min-width: 1024px){
       .app-header{
           justify-content: space-around;
           .header-login{
               display: block;
               padding: 0.5em 1em;
               border: 1px solid $primary-darken;
               font-size: 0.8em;
           }
           .header-registration{
               padding: 0.5em 1em;
               font-size: 0.8em;
           }
        
        }
    }
    @media screen and(min-width: 1250px){
       .app-header{
           justify-content: space-between;
           .header-login{
               display: block;
               padding: 1em 2em;
               border: 2px solid $primary-darken;
               font-size: 1em;
           }
           .header-registration{
               padding: 1em 2em;
               font-size: 1em;
           }
        
        }
    }
</style>