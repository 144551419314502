<template>
    <label class="container"> {{ options.label }}
        <input type="radio" :name="options.name" :id="options.id" @input="$emit('action', $event.target.id)" :disabled="options.disabled" :checked="options.checked">    
        <span class="checkmark"></span>
    </label>
</template>

<script>
    export default {
        props: {
            options: {
                label: {
                    type: String,
                    default: ""
                },
                name: {
                    type: String,
                    default: ""
                },
                id: {
                    type: String,
                    default: ""
                },
                disabled: {
                    type: Boolean,
                    default: false
                },
                checked: {
                    type: Boolean,
                    default: false
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
.container{
    font-size: 1em;
    font-weight: 700;
    display: block;
    position: relative;
    cursor: pointer;
    user-select: none;
    padding-left: 30px;
    width: 100%;
    // padding: 1em 0;
    margin: 0 0 2em;
}
.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    background-color: $white;
}


.container input:checked ~ .checkmark {
    background-color: $white;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $accent-dark;
}


.container input:checked ~ .checkmark:after {
    display: block;
}

</style>