<template>
    <div class="app-navigation-mobile" :class="{ 'navigation-mobile-translated' : isSubMenuOpened }">
        <AppHomeIcon @click="goToPage.home"/>
        <div class="heart-icon" @click="goToPage.favorites">
            <AppHeartIcon />
            <span class="counter">0</span>
        </div>
        <AppLoginIcon @action="openSubMenu"/>
        <!-- <AppSearchIcon @action="openMobileMenu"/> -->
        <AppMobileAsideMenu :isOpened="isMobileMenuOpened" />
        <div class="sub-menu">
            <button @click="goToPage.login">Вход</button>
            <button @click="goToPage.registration" class="accent">Регистрация</button>
        </div>
    </div>
</template>

<script>
import AppSearchIcon from "@/components/icons/app-search-icon.vue";
import AppMobileAsideMenu from "@/sections/app-mobile-aside-menu.vue";
import AppHomeIcon from "@/components/icons/app-home-icon.vue";
import AppLoginIcon from "@/components/icons/app-login-icon.vue";
import AppHeartIcon from "@/components/icons/app-heart-icon.vue";
import { useRouter } from "vue-router";
import { ref } from 'vue';
    export default {
        components: {
            AppLoginIcon,
            AppMobileAsideMenu,
            AppHomeIcon,
            AppSearchIcon,
            AppHeartIcon,
        },
        setup(){
            const router = useRouter();
            let isMobileMenuOpened = ref(false);
            const isSubMenuOpened = ref(false);
            const openMobileMenu = () => {
                isMobileMenuOpened.value = !isMobileMenuOpened.value;
            };

          const openSubMenu = () => {
                isSubMenuOpened.value = !isSubMenuOpened.value;
                if(isSubMenuOpened.value){
                    setTimeout(() => {
                        isSubMenuOpened.value = false;
                    }, 3000)
                }
            }

            const goToPage = {
                home: () => router.push('/'),
                registration: () => router.push('/registration'),
                login: () => router.push('/login'),
                favorites: () => router.push('/favorites'),
            }

            return {
                isMobileMenuOpened,
                isSubMenuOpened,
                openMobileMenu,
                openSubMenu,
                goToPage
            }
        }
    }
</script>

<style lang="scss" scoped>
    .app-navigation-mobile{
        height: 10%;
        width: 100%;
        left: 0;
        bottom: 0;
        position: fixed;
        background-color: $primary-dark;
        border-top: 1px solid $white;
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 1em;
        transition: 0.3s ease;
        .heart-icon{
            width: 30px;
            height: 30px;
            position: relative;
            .counter{
                position: absolute;
                left: 50%;
                top: 50%;
                color: $white;
                font-size: 0.8em;
                transform: translate(-22%, -35%)
            }
        }
        .sub-menu{
            width: 100%;
            height: 210%;
            position: absolute;
            left: 0;
            bottom: -210%;
            background-color: $primary-darken;
            border-top: 2px solid $white;
            display: flex;
            justify-content: space-around;
            align-items: center;
            button{
                padding: 1em 2em;
                border: transparent;
                border-radius: 0.4em;
                text-transform: uppercase;
                background-color: $primary;
                color: $black;
                font-weight: 700;
            }
            .accent{
                background-color: $accent-dark;
                color: $white;
            }
        }
    }


    .navigation-mobile-translated{
        transform: translateY(-200%);
    }

    @media screen and(min-width: 768px){
         .app-navigation-mobile{            
            justify-content: space-around;
            
        }
    }
    @media screen and(min-width: 1024px){
         .app-navigation-mobile{            
            display: none;
            
        }
    }
</style>