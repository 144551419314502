<template>
    <div class="app-mobile-aside-menu" :class="{ 'opened': isOpened }">
        <AppCityNavigation @openRegions="goToRegionsPage" />
        <AppMainFilter />
        <AppRegularBtn dark text="Найти" />
    </div>
</template>

<script>
import AppCityNavigation from "@/components/navigation/app-city-navigation.vue";
import AppMainFilter from "@/components/filters/app-main-filter.vue";
import AppRegularBtn from "@/components/buttons/app-regular-btn.vue";

    export default {
        components: {
            AppCityNavigation,
            AppMainFilter,
            AppRegularBtn
            
        },
        props: {
            isOpened: {
                type: Boolean
            }
        },
        methods: {
            checked(data){
                console.log(data)
            },
            goToRegionsPage(){
                this.$router.push('/regions')
            }
        },
        computed: {
            checkScreenHeght(){
                return window.innerHeight;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .app-mobile-aside-menu{
        position: fixed;
        top: 0;
        left: 0;
        width: 280px;
        height: 100%;
        background-color: $primary;
        border-right: 1px solid $white;
        transform: translateX(-100%);
        transition: 0.4s ease;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        padding: 2em 0;
    }
    .opened{
        transform: translateX(0);
    }

    @media screen and(max-height: 667px){
        .app-mobile-aside-menu{
            padding: 1em 0;
            justify-content: space-between;
        }
    }
</style>