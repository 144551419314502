<template>
	<div>
		<div class="double-range-slider">
			<div class="left-side-range-block" id='left-block'
				@mousedown="getLeftButtonPosition($event)"
				@mouseup="removeLeftRangeSliderEvent($event)"
				@mouseout="removeLeftRangeSliderEventByMouseOut"
				@click="setLeftRangeButtonPosition($event)"			
				>
			</div>
			<div class="range-line-left">
				<div class="range-line-fill" :style="`width:${leftRangeButtonPosition}%`"></div>
			</div>
			<div class="range-button-left" :style="`left:${leftRangeButtonPosition}px`">
				<div class="range-button-label">{{ leftLabelValue || rangeButtonLabelLeft }}</div>
			</div>

			<div class="right-side-range-block" id='right-block'
				@mousedown="getRightButtonPosition($event)"
				@mouseup="removeRightRangeSliderEvent($event)"
				@mouseout="removeRightRangeSliderEventByMouseOut"
				@click="setRightButtonPosition($event)"
				></div>
			<div class="range-line-right">
				<div class="range-line-fill" :style="`width:${rightRangeLinePosition}%`"></div>
			</div>
			<div class="range-button-right" :style="`right:${rightRangeButtonPosition}px`">
				<div class="range-button-label">{{ rightLabelValue || rangeButtonLabelRight }}</div>
			</div>
		</div>
		<div class="range-slider-title">Цена за час</div>
			<div class="range-fields">
				<div class="range-field">
					<span class="field-label">от</span>
					<input type="number" class="field-input" min='1000' max='10000' step="500" :value="leftLabelValue || rangeButtonLabelLeft" @input="setLeftRangeButtonPositonByInput($event.target.value)">
				</div>
				<div class="range-field">
					<span class="field-label">до</span>
					<input type="number" class="field-input" min='1000' max='10000' step="500" :value="rightLabelValue || rangeButtonLabelRight" @input="setRightRangeButtonPositionByInput($event.target.value)">
				</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			dropMinPrice: {
				type: Boolean,
				default: false
			},
			dropMaxPrice: {
				type: Boolean,
				default: false
			}
		},
		data(){
			return {
				leftRangeButtonPosition: 0,
				rightRangeButtonPosition: 0,
				rightRangeLinePosition: 100,
				leftLabelValue: null,
				rightLabelValue: null
			}
		},
		watch: {
			dropMinPrice: function(newPrice, oldPrice){
				this.dropMinPriceValues();
			},
			dropMaxPrice: function(newPrice, oldPrice){
				this.dropMaxPriceValues();
			}
		},
		methods: {
			dropMinPriceValues(){
				this.leftRangeButtonPosition = 0;
				this.leftLabelValue = null;
			},
			dropMaxPriceValues(){
				this.rightRangeButtonPosition = 0;
				this.rightRangeLinePosition = 100;
				this.rightLabelValue = null;
			},
			getLeftButtonPosition(){
				const rangeBlock = document.getElementById('left-block');
				rangeBlock.addEventListener('mousemove', this.setLeftRangeButtonPosition)
			},
			setLeftRangeButtonPosition(e){
				this.leftLabelValue = null;
				if(e.offsetX > 90){
						this.leftRangeButtonPosition = 90;
					} else {
						this.leftRangeButtonPosition = e.offsetX;
				}
			},
			setLeftRangeButtonPositonByInput(e){
				this.leftLabelValue = e;
				this.$emit('sendMinValue', e)
			},
			removeLeftRangeSliderEvent(e){
				const rangeBlock = document.getElementById('left-block');
				rangeBlock.removeEventListener('mousemove', this.setLeftRangeButtonPosition);
				this.setLeftRangeButtonPosition(e);
				this.$emit('sendMinValue', this.rangeButtonLabelLeft)
			},
			removeLeftRangeSliderEventByMouseOut(){
				const rangeBlock = document.getElementById('left-block');
				rangeBlock.removeEventListener('mousemove', this.setLeftRangeButtonPosition);
				// this.$emit('sendMinValue', this.rangeButtonLabelLeft)
			},
			getRightButtonPosition(){
				const rangeBlock = document.getElementById('right-block');
				rangeBlock.addEventListener('mousemove', this.setRightButtonPosition);
			},
			setRightButtonPosition(e){
				this.rightLabelValue = null;
				if(e.offsetX < 10){
					this.rightRangeButtonPosition = 100 - 10;
				} else {
					this.rightRangeButtonPosition = 100 - e.offsetX;
				}
				this.rightRangeLinePosition = e.offsetX;
			},
			setRightRangeButtonPositionByInput(e){
				this.rightLabelValue = e;
				this.$emit('sendMaxValue', e)
			},
			removeRightRangeSliderEvent(e){
				const rangeBlock = document.getElementById('right-block');
				rangeBlock.removeEventListener('mousemove', this.setRightButtonPosition);
				this.setRightButtonPosition(e);
				this.$emit('sendMaxValue', this.rangeButtonLabelRight)
			},
			removeRightRangeSliderEventByMouseOut(){
				const rangeBlock = document.getElementById('right-block');
				rangeBlock.removeEventListener('mousemove', this.setRightButtonPosition);
				// this.$emit('sendMinValue', this.rangeButtonLabelRight)
			}
		},
		computed: {
			rangeButtonLabelLeft(){

			
				let range;

				let min = 1000;
				let max = 10000;
				let percent = this.leftRangeButtonPosition / 100;

				range = Math.round(max * percent);

				if(range < min){
					range = min;
				} else if(range > max){
					range = max
				}

				return range;
			},
			rangeButtonLabelRight(){
				let range;

				let min = 1000;
				let max = 10000;
				let percent = 1 - (this.rightRangeButtonPosition / 100);
				

				range = Math.round(max * percent);

				if(range < this.rangeButtonLabelLeft){
					range = this.rangeButtonLabelLeft + 1000
				}

				if(range > 9700){
					range = max
				} else if(range < min){
					range = min
				}

				

				return range;
			}
		}
	}
</script>

<style lang="scss" scoped>
	.double-range-slider{
		width: 200px;
		height: 20px;
		display: flex;
		position: relative;
		margin-top: 10px;
		.left-side-range-block{
			width: 50%;
			height: 100%;
			z-index: 1000;
			cursor: pointer;
		}
		.range-line-left{
				position: absolute;
				width: 50%;
				height: 4px;
				top: 50%;
				left: 0;
				transform: translateY(-50%);
				z-index: 700;
				background-color: $accent-dark;
				.range-line-fill{
					width: 0;
					height: 100%;
					transform-origin: left;
					background-color: $accent;
					z-index: 800;
				}
			}
		.range-button-left{
			position: absolute;
			top: 50%;
			left: 0;
			transform: translate(-10px, -50%);
			border: 1px solid $accent-dark;
			border-radius: 100%;
			width: 20px;
			height: 20px;
			background-color: $white;
			z-index: 900;
			.range-button-label{
				position: absolute;
				top: -25px;
				font-weight: bold;
			}
			&::after{
				content: "";
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 10px;
				height: 10px;
				border-radius: 100%;
				background-color: $accent-dark;
			}

		}

		.right-side-range-block{
			width: 50%;
			height: 100%;
			z-index: 1000;
			cursor: pointer;
		}
		.range-line-right{
			position: absolute;
			width: 50%;
			height: 4px;
			top: 50%;
			left: 100px;
			transform: translateY(-50%);
			z-index: 700;
			background-color: $accent;
			.range-line-fill{
				width: 100%;
				height: 100%;
				width: 0;
				transform-origin: right;
				background-color: $accent-dark;
				z-index: 800;
			}
		}
		.range-button-right{
			position: absolute;
			top: 50%;
			transform: translate(10px, -50%);
			border: 1px solid $accent-dark;
			border-radius: 100%;
			width: 20px;
			height: 20px;
			background-color: $white;
			z-index: 900;
			.range-button-label{
				position: absolute;
				top: -25px;
				font-weight: bold;
			}
			&::after{
				content: "";
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 10px;
				height: 10px;
				border-radius: 100%;
				background-color: $accent-dark;
			}

		}
		
	}
	.range-slider-title{
            width: 100%;
            padding: 0.5em 0;
            font-size: 1em;
            font-weight: 700;
        }
         .range-fields{
                display: flex;
                justify-content: space-between;
                width: 100%;
                margin-bottom: 2em;
                .range-field{
                    width: 45%;
                    display: flex;
					align-items: center;
                    .field-label{
                        font-size: 1em;
                        font-weight: 700;
                        margin-right: 1em;
                    }
                    .field-input{
                        width: 63px;
						height: 25px;
                        border-radius: 3px;
                        border: 2px solid $accent;
						padding: 0 0 0 5px;
                    }
                }
            }
	
</style>